import { colors } from "ui";


const outlineA11y = `
    outline-width: 0.185rem;
    outline-color: ${colors.dark};
    outline-offset: 0.4rem;
    border-radius: 0.1875rem;
    outline-style: solid; 
`;

const pseudoA11yAfter = `
    &::after {
        content: '';
        position: absolute;
        top: 0.4rem;
        left: 0.4rem;
        right: 0.4rem;
        bottom: 0.4rem;
        border-radius: 0.6rem;
        box-shadow: 0 0 0 2px ${colors.dark};
        pointer-events: none;
    }
`;

export { outlineA11y, pseudoA11yAfter };