import colors from "ui/colors"
import { fontSize, fluidFontSize } from "ui/fontSize"
import lineHeight from "ui/lineHeight"
import { breakpoint as breakpoints, sizes } from "ui/breakpoints"
import spacer from "ui/spacer"
import zIndex from "ui/z-index"
import { outlineA11y, pseudoA11yAfter } from "ui/a11y"
import { fontFamily } from "ui/fonts"

const maxWidth = "1400px"
const transitionTime = 200
export {
  colors,
  breakpoints,
  fontSize,
  lineHeight,
  maxWidth,
  transitionTime,
  fontFamily,
  fluidFontSize,
  spacer,
  zIndex,
  sizes,
  outlineA11y,
  pseudoA11yAfter,
}
