import { css } from "@emotion/core"
import { breakpoint as breakpoints } from "./breakpoints"

function spacer([small, medium, large, huge]) {
  return css`
    ${Number.isInteger(small) && `margin-bottom: ${small}px;`}

    ${breakpoints("medium")} {
      ${Number.isInteger(medium) && `margin-bottom: ${medium}px;`}
    }

    ${breakpoints("large")} {
      ${Number.isInteger(large) && `margin-bottom: ${large}px;`}
    }

    ${breakpoints("huge")} {
      ${Number.isInteger(huge) && `margin-bottom: ${huge}px;`}
    }
  `
}

export default spacer
